import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export function useSalutations() {
    const store = useStore()
    const salutations = computed(() => store.getters.salutations)

    const getSalutations = () => {
        store.dispatch('fetchSalutations')
    }

    onMounted(() => {
        if (salutations.value && salutations.value.length) {
            return
        }

        getSalutations()
    })

    return { salutations, getSalutations }
}
